import { format } from "date-fns"
import { gregorianToBazi, dateObjectToBazi } from "../FourPillar"
import {
  SIXTY_PILLAR,
  DIRECTION,
  QM_SAN_YUAN,
  SIXTY_FOUR,
  TRIGRAM_BINARY,
  STAR,
  DOOR,
} from "../constants"
import Ju from "../reference/Ju.json"
import { addHours, parseISO } from "date-fns"
import { DATE_FORMAT } from "../../constants"
const CalenderData = require("./Calendar.json")

export const get3VWithinRange = (dob, dateString, range, method) => {
  const natalChart = getZhiRunHourChart(parseISO(dob))
  const date = parseISO(dateString)
  return [...Array(range).keys()].map(e => {
    date.setDate(e + 1)
    const monthChartObj = getMonthChartByDate(date)
    const monthChart = lookupChart(
      monthChartObj.structure,
      monthChartObj.pillar
    )
    const dayChartObject = getDayChartByDate(date)
    const dayChart = lookupChart(
      dayChartObject.structure,
      dayChartObject.pillar
    )
    const isDivineLightDate = isDivineLightDay(
      natalChart.p.divineLight,
      monthChart.chart,
      dayChart.chart
    )
    const isDivineLight3Overlap = isDivineLightDay(
      findChiefPalace(natalChart.ju.chart).palace,
      monthChart.chart,
      dayChart.chart
    )

    return {
      date: format(date, DATE_FORMAT),
      isDivineLight3Overlap,
      isDivineLightDate,
      isDivineBlessDate: isDivineBlessingDay(
        natalChart.p.divineLight,
        monthChart.chart,
        dayChart.chart
      ),
      isDivineForceDate: isDivineForceDay(
        natalChart.p.divineLight,
        monthChart.chart,
        dayChart.chart
      ),
    }
  })
  //.filter(e => e.isDivineLightDate || e.isDivineBlessDate || e.isDivineForceDate)
}

export const isDivineLightDay = (sector, monthChart, dayChart) => {
  const sectorIndex = DIRECTION.findIndex(e => e === sector)
  return (
    (monthChart[sectorIndex].door === "生" &&
      dayChart[sectorIndex].god === "天") ||
    (monthChart[sectorIndex].god === "天" &&
      dayChart[sectorIndex].door === "生")
  )
}

export const isDivineBlessingDay = (sector, monthChart, dayChart) => {
  const sectorIndex = DIRECTION.findIndex(e => e === sector)
  return (
    (monthChart[sectorIndex].door === "生" &&
      dayChart[sectorIndex].god === "符") ||
    (monthChart[sectorIndex].god === "符" &&
      dayChart[sectorIndex].door === "生")
  )
}

export const isDivineForceDay = (sector, monthChart, dayChart) => {
  const sectorIndex = DIRECTION.findIndex(e => e === sector)
  return (
    (monthChart[sectorIndex].god === "天" &&
      dayChart[sectorIndex].god === "符") ||
    (monthChart[sectorIndex].god === "符" && dayChart[sectorIndex].god === "天")
  )
}

export const searchHourChart = (startDate, endDate, searchFormation) => {
  const result = []
  const found = Ju.filter(v =>
    v.chart.find(
      g => searchFormation && g.f && g.f.indexOf(searchFormation) > -1
    )
  ).map(e => ({ structure: e.structure, hour: e.hour }))

  while (startDate < endDate) {
    const ju = getZhiRunHourChart(startDate)

    if (
      found.find(e => ju.ju.structure === e.structure && ju.ju.hour === e.hour)
    ) {
      result.push(startDate)
    }
    startDate = addHours(startDate, 2)
  }
  return result
}

export const getZhiRunHourChart = dt => {
  const bazi = dateObjectToBazi(dt)
  const yearMonthKey = format(dt, "yyyyMM")
  const dayOfMonth = format(dt, "d")
  const structure = CalenderData[yearMonthKey][dayOfMonth - 1]
  const ju = Ju.find(
    e => e.structure === structure && e.hour === bazi.GanZhiHour.ganzhi
  )

  const chiefPalace = ju.chart.findIndex(e => e.god === "符")
  const originalStarPalace =
    STAR[chiefPalace > 4 ? chiefPalace - 1 : chiefPalace]
  const profile = {
    dayStem: bazi.GanZhiDay.ganzhi.substring(0, 1),
    hourStem: bazi.GanZhiHour.ganzhi.substring(0, 1),
    divineBless: findDivineBlessing(ju.chart).palace,
    divineLight: findDivineLight(ju.chart, originalStarPalace).palace,
    divineForce: findDivineForce(ju.chart).palace,
  }
  return {
    ju: {
      ...ju,
      isoString: dt.toISOString(),
      bazi,
    },
    p: profile,
    profile: [
      {
        title: "Day Stem",

        label: profile.dayStem,
      },
      {
        title: "Hour Stem",
        label: profile.hourStem,
      },
      {
        title: "Divine Blessing",
        label: profile.divineBless,
        description: "Initate Project",
      },
      {
        title: "Divine Light",
        description: "Self Improvement",
        label: profile.divineLight,
      },
      {
        title: "Divine Force",
        description: "Negotiation",
        label: profile.divineForce,
      },
    ],
  }
}

export const getChaiBuHour = bazi => {
  const chart = getQMCBChart(bazi)
  const type =
    Object.keys(QM_SAN_YUAN).findIndex(e => e === bazi.solarTerm) < 12
      ? "M"
      : "F"
  return {
    pillar: bazi.GanZhiHour.ganzhi,
    structure: `${type}${chart.substr(1, 1)}`,
  }
}

export const getQMCBChart = bazi => {
  const branchYuan = [
    ["子", "卯", "午", "酉"],
    ["寅", "巳", "申", "亥"],
    ["丑", "辰", "未", "戌"],
  ]

  const reverseSixty = [
    ...SIXTY_PILLAR.slice().reverse(),
    ...SIXTY_PILLAR.slice().reverse(),
  ]
  const rest = reverseSixty.slice(
    reverseSixty.findIndex(e => e === bazi.GanZhiDay.ganzhi)
  )

  const fuTouBranch = rest
    .find(e => ["己", "甲"].includes(e.substr(0, 1)))
    .substr(1, 1)
  const yuan = branchYuan.findIndex(e => e.includes(fuTouBranch))
  const type =
    Object.keys(QM_SAN_YUAN).findIndex(e => e === bazi.solarTerm) < 12
      ? "M"
      : "F"

  return `${type}${QM_SAN_YUAN[bazi.solarTerm][yuan]}`
}

const getQMChart = (y, m, d, h) => {
  const calDate = new Date(y, m - 1, d)
  const yearMonthKey = format(calDate, "yyyyMM")
  const dayIndex = Number(format(calDate, "d")) - 1
  return {
    pillar: h,
    structure:
      CalenderData[yearMonthKey] && CalenderData[yearMonthKey][dayIndex],
  }
}

export const getZRQMHourChart = (y, m, d, h) => {
  return getHourChart(gregorianToBazi(y, m, d, h))
}

export const getHourChart = bazi => {
  return getQMChart(
    bazi.westernYear,
    bazi.westernMonth,
    bazi.westernDay,
    bazi.GanZhiHour.ganzhi
  )
}

export const searchChart = (filterObject, haystack) => {
  return haystack
    .filter(v => {
      const kk = v.chart.map((obj, i) => {
        const compare = filterObject[i]
        const l = Object.keys(compare).map(k => {
          const o = compare[k]
          return o === "" ? true : o.indexOf(obj[k]) > -1 ? true : false
        })
        return l.every(e => e)
      })
      return kk.every(e => e)
    })
    .map(e => ({ structure: e.structure, hour: e.hour }))
}

const element = {
  red: ["S", "丁", "丙", "景", "英", "巳", "午", "離"],
  "#00739D": ["N", "壬", "癸", "休", "蓬", "子", "亥", "坎"],
  "#145A32": [
    "E",
    "SE",
    "乙",
    "杜",
    "傷",
    "辅",
    "沖",
    "輔",
    "寅",
    "卯",
    "震",
    "巽",
    "甲",
  ],
  "#A2423D": [
    "NE",
    "SW",
    "死",
    "生",
    "芮",
    "任",
    "戊",
    "己",
    "丑",
    "辰",
    "未",
    "戌",
    "艮",
    "坤",
  ],
  "#777777": [
    "W",
    "NW",
    "庚",
    "辛",
    "开",
    "柱",
    "心",
    "驚",
    "開",
    "申",
    "酉",
    "乾",
    "兌",
  ],
}

export const getColor = word => {
  const color = Object.keys(element)
  return color.find(colorCode => element[colorCode].includes(word))
}

export const getDayChartByDate = date => {
  return getDayChart(date.getFullYear(), date.getMonth() + 1, date.getDate())
}

export const getDayChart = (year, month, day) => {
  const lookup = {
    9: ["夏至", "小暑", "大暑", "立秋"],
    1: ["冬至", "小寒", "大寒", "立春"],
    3: ["处暑", "白露", "秋分", "寒露"],
    7: ["雨水", "惊蛰", "春分", "清明"],
    6: ["霜降", "立冬", "小雪", "大雪"],
    4: ["谷雨", "立夏", "小满", "芒种"],
  }
  const removeSign = n => (n < 0 ? n * -1 : n)
  const bazi = gregorianToBazi(year, month, day, 12)
  const start = Object.keys(lookup).find(startJu =>
    lookup[startJu].includes(bazi.solarTerm)
  )
  const chartType = ["1", "7", "4"].includes(start) ? "M" : "F"
  const index = SIXTY_PILLAR.indexOf(bazi.GanZhiDay.ganzhi)
  const chart =
    chartType === "M"
      ? (index - 1 + Number(start)) % 9
      : removeSign(start - Number(index)) % 9
  const structure =
    chartType === "M" ? `${chartType}${chart + 1}` : `${chartType}${9 - chart}`
  return { structure, pillar: bazi.GanZhiDay.ganzhi }
}

export const matchCriteria = (chart1, chart2, palaceIndex, contains) => {
  return (
    contains.filter(e =>
      [
        chart1[palaceIndex].god,
        chart1[palaceIndex].door,
        chart1[palaceIndex].star,
        chart2[palaceIndex].god,
        chart2[palaceIndex].door,
        chart2[palaceIndex].star,
      ].includes(e)
    ).length === contains.length
  )
}

export const findChiefPalace = chart => findSymbol(chart, "god", "符")

export const findDivineBlessing = chart => findSymbol(chart, "god", "天")

export const findDivineLight = (chart, star) => findSymbol(chart, "star", star)

export const findDivineForce = chart => findSymbol(chart, "door", "生")

export const findSymbol = (chart, key, word) => {
  return {
    palace: DIRECTION[chart.findIndex(e => e[key] === word)],
    symbol: word,
  }
}

export const starDoorToHexagram = (star, door) => {
  const doorBinaryIndex = DOOR.findIndex(e => e === door)
  const starBinaryIndex = STAR.findIndex(e => e === star)

  const hexBinary =
    TRIGRAM_BINARY[doorBinaryIndex] + TRIGRAM_BINARY[starBinaryIndex]
  return SIXTY_FOUR[hexBinary]
}

export const getMonthChartByDate = date => {
  return getMonthChart(date.getFullYear(), date.getMonth() + 1, date.getDate())
}

export const lookupChart = (structure, hour) => {
  return Ju.find(e => e.structure === structure && e.hour === hour)
}

export const getMonthChart = (year, month, day) => {
  const yin = [1, 4, 7]
  const bazi = gregorianToBazi(year, month, day, 12)

  const lookup = [
    ...SIXTY_PILLAR.slice(0, 5),
    ...SIXTY_PILLAR.slice(15, 20),
    ...SIXTY_PILLAR.slice(30, 35),
    ...SIXTY_PILLAR.slice(45, 50),

    ...SIXTY_PILLAR.slice(5, 10),
    ...SIXTY_PILLAR.slice(20, 25),
    ...SIXTY_PILLAR.slice(35, 40),
    ...SIXTY_PILLAR.slice(50, 55),

    ...SIXTY_PILLAR.slice(10, 15),
    ...SIXTY_PILLAR.slice(25, 30),
    ...SIXTY_PILLAR.slice(40, 45),
    ...SIXTY_PILLAR.slice(55, 60),
  ]

  const index = lookup.indexOf(bazi.GanZhiYear.ganzhi)
  const ju = yin.find((ju, i) => i * 20 - 1 < index && index < (i + 1) * 20)
  const structure = `F${ju}`
  return { structure, pillar: bazi.GanZhiMonth.ganzhi }
}

export const getSanYuanYearChart = date => {
  const year1928Structure = 9

  const bazi = gregorianToBazi(
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
    date.getHours()
  )
  const toYear = ["小寒", "大寒"].includes(bazi.solarTerm)
    ? date.getFullYear() - 1
    : date.getFullYear()
  const newStructure = year1928Structure - ((toYear - 1928) % 9)
  return {
    pillar: bazi.GanZhiYear.ganzhi,
    structure: `F${newStructure}`,
  }
}
